import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';

const RightOfWaySurveys = () => (
  <Layout
    title="Right-of-Way Surveys Calgary & Area | Axiom Geomatics"
    description="Utility, overland drainage and maintenance access right-of-ways could have rights over your property that restrict what you are able to do within your property."
    keywords=""
  >
    <main>
      <h1>Right-of-Way Surveys Calgary & Area</h1>
      <p>
        <strong>Right-of-Way surveys</strong> provide a way of granting rights to a piece of land
        without giving away ownership. These third-party rights are enshrined within the Land Title.
        The specific details of these rights are registered at the Land Titles Office.
      </p>

      <p>The third-parties are commonly:</p>

      <ul>
        <li>Government (e.g. access and utilities)</li>
        <li>Corporations (e.g. utilities such as Enmax, Atco or Shaw)</li>
        <li>Private individuals (e.g. shared driveways, land drainage…)</li>
        <li>
          Like subdivisions, Alberta Land Surveyors can create Right-of-Ways by registering plans at
          the Land Titles Office.
        </li>
      </ul>

      <h2>
        <abbr title="Frequently Asked Questions">FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#right-of-way">Do you survey and mark Right-of-Ways?</Link>
        </li>
        <li>
          <Link to="#on-property">How do I know if I have Right-of-Ways on my property?</Link>
        </li>
        <li>
          <Link to="#build">Am I allowed to build in Right-of-Ways?</Link>
        </li>
      </ul>

      <h3 id="right-of-way">Do you survey and mark Right-of-Ways?</h3>
      <p>Yes, we survey and mark any boundary including right-of-ways.</p>

      <h3 id="on-property">How do I know if I have Right-of-Ways on my property?</h3>
      <p>
        Properties vary greatly in the number and location of right-of-ways, you will need to
        contact an <a href="https://axiomgeomatics.ca/about-us">Alberta Land Surveyor</a> to discuss
        your specific property.
      </p>

      <h3 id="build">Am I allowed to build in Right-of-Ways?</h3>
      <p>In most cases it is not advisable to build in a Right-of-Way.</p>
    </main>
  </Layout>
);

export default RightOfWaySurveys;
